<template>

  <!-- Section: Social media -->
  <section class="p-2 border-top border-2 border-secondary footer">
    <div class="flex-center p-2 text-secondary">BlkDem for Umolab Group &copy; 2022</div>
    <!-- Left -->
    <div class="flex-center icon-social">
      <div class="mx-1">
        <a class="gold" href="https://github.com/BlkDem" target="_blank" title="https://github.com/BlkDem">
          <i class="fa-brands fa-square-github fa-2x" size="2xl"></i>
        </a>
      </div>
      <div class="mx-1">
        <a class="gold" href="https://linkedin.com/in/blkdem" target="_blank" title="https://linkedin.com/in/blkdem">
          <i class="fa-brands fa-linkedin fa-2x" size="2xl"></i>
        </a>
      </div>
      <div class="mx-1">
        <a class="gold" href="mailto:blkdem@blkdem.ru" target="_blank" title="blkdem@blkdem.ru">
          <i class="fa-solid fa-square-envelope fa-2x" size="2xl"></i>
        </a>
      </div>
      <div class="mx-1">
        <a class="gold" href="https://wa.me/79039073355" target="_blank" title="+7 903 907-3355 / +90 501 082-0257">
          <i class="fa-brands fa-square-whatsapp fa-2x" size="2xl"></i>
        </a>
      </div>
      <div class="mx-1">
        <a class="gold" href="https://t.me/Umolab1" target="_blank" title="https://t.me/Umolab1">
          <i class="fa-brands fa-telegram fa-2x" size="2xl"></i>
        </a>
      </div>
      <div class="mx-1">
        <a class="gold"
          href="https://www.google.com/maps/place/36%C2%B053'43.7%22N+30%C2%B042'14.0%22E/@36.895482,30.7013021,17z/data=!3m1!4b1!4m4!3m3!8m2!3d36.895482!4d30.703877"
          target="_blank" title="Antalya, Turkey">
          <i icon="fa-solid fa-location-crosshairs" size="2xl"></i>
        </a>
      </div>
    </div>

    <!-- Right -->
  </section>
</template>

<script>

</script>

<style>
.footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
